@mixin bp-mobile {
    @media only screen and (max-width: 640px) {
        @content;
    }
}

@mixin bp-tablet {
    @media only screen and (min-width: 641px) and (max-width: 1023px) {
        @content;
    }
}

@mixin bp-tablet-max {
    @media only screen and (max-width: 1023px) {
        @content;
    }
}

@mixin bp-lg-xl {
    @media only screen and (min-width: 1024px) and (max-width: 1279px) {
        @content;
    }
}

@mixin bp-xl-2xl {
    @media only screen and (min-width: 1280px) and (max-width: 1535px) {
        @content;
    }
}

@mixin bp-2xl {
    @media only screen and (min-width: 1536px) {
        @content;
    }
}

@mixin bp-desktop-min {
    @media only screen and (min-width: 1024px) {
        @content;
    }
}

@mixin trans($type: all, $time: 0.25s) {
    transition: $type $time ease 0s;
}

@function to-number($value) {
    @if type-of($value)=='number' {
        @return $value;
    }

    @else if type-of($value) !='string' {
        @error 'Value for `to-number` should be a number or a string.';
    }

    $result: 0;
    $digits: 0;
    $minus: str-slice($value, 1, 1)=='-';
    $numbers: (
        '0': 0,
        '1': 1,
        '2': 2,
        '3': 3,
        '4': 4,
        '5': 5,
        '6': 6,
        '7': 7,
        '8': 8,
        '9': 9,
    );

@for $i from if($minus, 2, 1) through str-length($value) {
    $character: str-slice($value, $i, $i);

    @if (index(map-keys($numbers), $character) or $character =='.') {
        @if $character =='.' {
            $digits: 1;
        }

        @else if $digits ==0 {
            $result: $result * 10+map-get($numbers, $character);
        }

        @else {
            $digits: $digits * 10;
            $result: $result +map-get($numbers, $character) / $digits;
        }
    }
}

@return if($minus, -$result, $result);
}

//  Helper function to replace characters in a string
@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);
    @return if($index,
        str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace),
        $string );
}

/*
 * Usage: calc-based(number-reference, vw or vh)
 */
@function calc-based($number, $axis: vw) {
    $base: 0;

    $number: quote($number);
    $number: str-replace($number, 'px');
    $number: str-replace($number, 'em');
    $number: str-replace($number, 'rem');
    $number: str-replace($number, 'pt');
    $number: str-replace($number, 'vw');
    $number: str-replace($number, 'vh');
    $number: to-number($number);

    @if $axis ==vh {
        $base: 640;
    }

    @else {
        $base: 360;
    }

    $myCalc: (
        $number * 100) / $base;

    @return #{$myCalc}#{$axis};
}