.main-administration {
  .actions {
    display: flex;
    gap: 2rem;
    button {
      width: 100%;
      border-radius: 10px;
      border: 1px solid transparent;
      background: transparent;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      padding: 2rem 1rem;
      margin: 0;
      color: #fff;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      &:active,
      &.active {
        border: 1px solid #fff;
      }
    }
  }
}

.main-adm-ideias {
  .form {
    display: flex;
    flex-wrap: wrap;
    .form-group {
      width: 100%;
      display: flex;
      gap: 2rem;
      label {
        width: 100%;
        select,
        input {
          width: 100%;
          background: transparent;
          border: solid 1px #fff;
          margin-bottom: 1rem;
          border-radius: 10px;
          padding: 10px;
        }
      }
    }
  }
  .trail-card {
    &.main-ideia {
      // align-items: center;
      width: 100%;
      .text-gray-300 {
        flex: 1;
      }
      .text {
        p {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        .title {
          color: #735cc6;
        }
        .description {
          color: #69696e;
          text-decoration-line: underline;
          cursor: pointer; 
        }
        .user {
          color: #69696e;
          font-size: 10px;
        }
      }
      .avaliation {
        display: flex;
        gap: 2rem;
        border-top: solid 1px #69696e;
        width: 100%;
        padding: 0.5rem;
        margin-top: 0.5rem;
        justify-content: space-between;
        .like {
          button {
            display: flex;
          }
        }
        .avalie {
          button {
            border-radius: 10px;
            background: #735cc6;
            color: #fff;
            text-align: center;
            padding: 0.5rem 2rem;
            margin-left: 2rem;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }
      }
    }
    img {
      width: 80px;
      height: 80px;
      border-radius: 200px;
    }
  }
  .form-avaliation {
    background: #fff;
    margin-left: -1.75rem;
    padding: 0 1.75rem 0 1.75rem ;
    width: calc(100% + 3.5rem);
    position: relative;
    margin-bottom: 2rem;
    transition: all ease-in-out 0.5s;
    max-height: 0;
    overflow: hidden;
    button.close {
      color: #69696e;
      position: absolute;
      top: 1rem;
      right: 2rem;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .content {
      display: flex;
      .figure {
        padding-right: 2rem;
        figure {
          width: 120px;
          height: 120px;
          img {
            width: 120px;
            height: 120px;
            object-fit: cover;
          }
        }
      }
      .avaliation-data {
        width: 100%;
        p,
        span {
          color: #69696e;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 17px;
          &.title {
            color: #735cc6;
          }
          &.user {
            margin-top: 1rem;
          }
        }
        span {
          font-weight: 300;
        }
        .boxparticipantes {
          padding: 2rem;
          border: solid 1px #69696e;
          margin: 1rem 0;
          border-radius: 10px;
          width: 100%;
          .itens {
            display: flex;
            margin-top: 1rem;
            flex-wrap: wrap;
            justify-content: space-between;
            .item {
              width: 48%;
              margin-bottom: 0;
              display: flex;
              align-items: center;
              figure {
                img {
                  width: 100%;
                  width: 40px;
                  height: 40px;
                  object-fit: cover;
                  margin-right: 10px;
                }
              }
            }
          }
        }
        .link {
          a {
            color: #735cc6;
            margin-bottom: 2rem;
            display: block;
            word-break: break-word;
          }
        }
        .file {
          button {
            border-radius: 10px;
            background: #735cc6;
            color: #fff;
            text-align: center;
            padding: 0.5rem 2rem;
            margin: 1rem 0;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }
        hr {
          display: block;
          width: 100%;
          margin-bottom: 2rem;
        }
        textarea {
          width: 100%;
          height: 100px;
          border-radius: 10px;
          border: solid 1px #735cc6;
          padding: 1rem;
          margin: 1rem 0;
          resize: none;
          color: #69696e;
        }
        .actions {
          display: flex;
          justify-content: space-between;
          .avaliation {
            display: flex;
            align-items: center;
            p {
              margin-right: 1rem;
            }
            .setAvaliation{
              display: flex;
              svg {
                cursor: pointer;
                width: 28px;
              }
            }
          }
          button {
            border-radius: 10px;
            background: #735cc6;
            color: #fff;
            text-align: center;
            padding: 0.5rem 2rem;
            margin: 1rem 0;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            width: auto;
          }
        }
      }
    }
    &.open {
      padding: 3rem 1.75rem 1.75rem 1.75rem ;
      max-height: 2400px;
    }
  }

  .my-position {
    border-top: 1px solid yellow;
    border-bottom: 1px solid yellow;
    padding-bottom: 20px;
  } 
    
  .pagination {
      display: flex;
      list-style: none;
      justify-content: center;
      align-items: center;
      padding: 0;

      &.hidden {
          display: none;
      }
  }
    
  .pagination li {
      margin: 0 5px;
      display: inline-block;
      color: #fff;
  }
    
  .pagination a {
      display: block;
      padding: 8px 12px;
      border: 1px solid #ccc;
      color: #69696ed9;
      text-decoration: none;
      transition: background-color 0.3s, color 0.3s;
  }
    
  .pagination a:hover {
      background-color: #003268;
      color: #fff;
  }
    
  .pagination .selected a {
      background-color: #003268;
      color: #fff;
      border-color: #003268;
  }
    
  .pagination .break a {
      cursor: default;
  }
}
