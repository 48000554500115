.user-name{
  word-break: break-all;
}
.resume-badges {
  cursor: pointer;
  div {
    img {
      height: 30px;
    }
    &.incomplet {
      opacity: 0.5;
    }
  }
}
