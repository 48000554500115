.main-modalIdeia {
  // width: 100vw;
  // height: 100vh;
  // position: fixed;
  // top: 0;
  // left: 0;
  // background: rgba($color: #000000, $alpha: 0.3);
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // z-index: 999999999;
  .form-add-ideia {
    max-width: 1200px;
    width: 100%;
    padding: 2rem;
    border-radius: 10px;
    position: relative;
    > button.close {
      color: #69696e;
      font-size: 20px;
      top: 1rem;
      right: 1rem;
      position: absolute;
      min-width: 50px;
      cursor: pointer;
      z-index: 999;
    }
    .content-form {
      padding: 2rem;
      .boxheader {
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;
        margin-top: 1rem;
        figure {
          img {
            width: 150px;
            height: 150px;
            border-radius: 800px;
            object-fit: cover;
          }
        }
        .box {
          flex: 1;
          .formGroup {
            width: 100%;
          }
        }
      }
    }
    .formGroup {
      input,
      select,
      textarea,
      .select {
        width: 100%;
        margin-top: 1rem;
        border-radius: 10px;
        border: solid 1px #bdb6ff;
        padding: 0.8rem;
        background: white;
        font-size: 15px;
        font-size: 14px;
        line-height: 16px;
        color: #69696e;
        &::placeholder {
          font-size: 14px;
          line-height: 16px;
          color: #69696e;
        }
        &:disabled {
          opacity: 0.5;
        }
      }
      select {
        appearance: none;
        background: url(../../assets/img/down.png) no-repeat;
        background-position: 99% center;
      }
      .select {
        padding: 0;
        .css-13cymwt-control {
          background: none;
          border: none;
          padding-right: 1%;
        }
      }
      .custom-file-upload {
        cursor: pointer;
        display: block;
        width: 100%;
        margin-top: 1rem;
        border-radius: 10px;
        border: solid 1px #bdb6ff;
        padding: 0.8rem;
        background: white;
        font-size: 15px;
        font-size: 14px;
        line-height: 16px;
        color: #69696e;
        background: url(../../assets/img/file.png) no-repeat;
        background-position: 99% center;
        input {
          display: none;
        }
        &.disabled {
          opacity: 0.5;
        }
      }

      &.actions {
        display: flex;
        justify-content: flex-end;
        button {
          background: #735cc6;
          color: #fff;
          margin-top: 2rem;
          padding: 1rem 7rem;
          border: none;
          border-radius: 10px;
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          color: #ffffff;
          &:disabled {
            opacity: 0.5;
          }
        }
      }
    }
    &.open {
      max-height: 2000px;
    }
  }
}

.modal-galery {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba($color: #000000, $alpha: 0.7);
  .content-modalGalery {
    background: #fff;
    // max-width: 1200px;
    width: 100%;
    padding: 4rem 2rem 2rem 2rem ;
    border-radius: 10px;
    position: relative;
    > button.close {
      color: #69696e;
      font-size: 20px;
      top: 1rem;
      right: 1rem;
      position: absolute;
      min-width: 50px;
      cursor: pointer;
      z-index: 999;
    }
    .list-photos {
      display: flex;
      gap: 2rem;
      justify-content: space-around;
      figure {
        img {
          cursor: pointer;
        }
      }
    }
    .actions {
      display: flex;
      // margin-top: 2rem;
      // justify-content: center;
      span {
        display: block;
        background: #735cc6;
        cursor: pointer;
        color: #fff;
        margin-top: 1rem;
        padding: 1rem 7rem;
        border: none;
        border-radius: 10px;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #ffffff;
      }
    }
  }
}

#file-upload-imagem {
  display: none;
}