@tailwind base;
@tailwind components;
@tailwind utilities;

body{
	@apply p-safe text-gray-900 antialiased font-sans;

	/* PWA enhancements */
	-webkit-overflow-scrolling: touch;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;

}

html{
	height: 100vh;
  -webkit-overflow-scrolling: touch;

}

#modal {
	z-index: 9999;
}

input[type=text] {
	font-size: 16px !important;
}