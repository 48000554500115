@import '../../assets/styles/mixin';

.main-extrato {
  .content-extrato {
    --text-color:#fff;
    --select-bg:#fff;
    --border-select-color:#fff;
    --thead-bg:#bdb6ff;
    --thead-text-color:#69696e;
    --table-border-color:#fff;
    --table-text-color:#fff;
    &.has-contrast {
      --text-color:#fcfc30;
      --select-bg:#000;
      --border-select-color:#fcfc30;
      --thead-bg: #000;
      --thead-text-color: #fcfc30;
      --table-border-color: #fcfc30;
      --table-text-color: #fcfc30;
    }
    .action-date {
      margin-bottom: 2rem;
      label {
        span {
          color: var(--text-color);
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
          margin-right: 1rem;
        }
        input {
          border-radius: 10px;
          border: 1px solid var(--text-color);
          color:var(--text-color);
          background: transparent;
          padding: 0.2rem 1rem;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          margin-right: 1rem;
          appearance: none;
        }
      }

    }


    table {
      width: 100%;
      thead {
        tr {
          th {
            background: var(--thead-bg);
            padding: 0.7rem 1rem;
            color: var(--thead-text-color);
            text-align: left;
            text-transform: uppercase;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            &:first-child {
              border-radius: 10px 0 0 10px;
            }
            &:last-child {
              border-radius: 0 10px 10px 0;
            }
          }
        }
      }
      tbody {
        tr {
          border-bottom: solid 1px var(--table-border-color);
          td {
            padding: 0.7rem;
            @include bp-mobile{
              padding:0.3rem;
            }
            color: var(--table-text-color);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
            .actions {
              display: flex;
              align-items: center;
              gap: 1rem;
            }
          }
        }
      }
    }

    &.has-contrast {
      .action-date {
        select {
          border: 1px solid var(--border-select-color);
          border-radius: 8px;
          background: #000;
        }
      }

      table{
        thead{
          tr{
            th{
              border-bottom:1px solid #fcfc30;
            }
          }
        }
      }
    }

    .pagination > {
      li  {
        &.active {
          background-color: #FFF;
          a {
            color: #735CC6;
          }
        }
      }
    } 
  }
  


      
    

}
