.box{
    align-items: center;
    background: rgba(255,255,255,0.9);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    .title {
        font-family: 'BancoDoBrasilTitle';
        font-weight: 500;
    }

    .stats{
        border-top:1px solid #69696E;
        display: flex;
        justify-content: space-between;
        margin-top: 74px;
        padding-top: 13px;
        width: 100%;

        > p{
            margin:0;
        }
    }

    &.has-contrast{
        background: #000;
        border:1px solid #fcfc30;
        .stats{
            border-color:#fcfc30;
        }
    }
}
