@import '../../assets/styles/mixin';



.user-progress-bar{
    display: flex;
    width:100%;
    @include bp-mobile {
        position: relative;
        width: 100%;
    }
    > span{
        max-width: 5%;

        @include bp-mobile {
            position: absolute;
            top:-15px;
            max-width: auto;

            &:last-child{
                right:40px;
            }
        }
    }
    .bar{
        height:18px;
        border-radius: 100px;

        @include bp-mobile{
            height:10px;
            margin-top:2px;
            width: 100%;
        }

        > div{
            border-radius: 100px;
            -webkit-transition: width 1s ease-in-out;
                -moz-transition: width 1s ease-in-out;
                -o-transition: width 1s ease-in-out;
                transition: width 1s ease-in-out;
        }
    }

    .thumb{
        align-items: center;
        border-radius: 100%;
        display: flex;
        height: 43px;
        justify-content: center;
        padding:3px;


        > div{
            background: #fff;
            border-radius: 100%;
            padding:4px;
            height: 100%;
            width: 100%;
        }
    }

    .wrapper-bar{
        margin:0 20px;
        margin-left: 30px;
        min-width:85%;
        @include bp-mobile {
            margin-right:0;
        }
        > div{
            transition: 0.3s all;
        }
    }
    
}