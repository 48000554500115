#pageRankingConhecimento {
    .my-position {
        border-top: 1px solid yellow;
        border-bottom: 1px solid yellow;
        padding-bottom: 20px;
    } 
      
    .pagination {
        display: flex;
        list-style: none;
        justify-content: center;
        align-items: center;
        padding: 0;

        --color:#fff;
        --border:#ccc;
        &.has-contrast{
            --color:#fcfc30;
            --border: #fcfc30;
        }

        &.hidden {
            display: none;
        }

        li {
            margin: 0 5px;
            display: inline-block;
            color: var(--color);

            a {
                display: block;
                padding: 8px 12px;
                border: 1px solid var(--border);
                color: var(--color);
                text-decoration: none;
                transition: background-color 0.3s, color 0.3s;
        
                &:hover {
                    background-color: #f5f5f5;
                    color: #555;
                }
            }

            &.selected a{
                background-color: var(--color);
                color: #000;
                border-color: var(--border);
            }
        }

        
        
    }
}