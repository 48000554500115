.item-position{
  .thumb {
    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      object-fit: cover;
    }

    &.imgMy {
      width: 95px;
    }
  }
}