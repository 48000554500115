.tutorial {
  position: relative;
  .item {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #838388;
    text-align: center;
    min-height: 300px;
    padding: 10px 0;
    img {
      margin: 1.25rem auto;
      max-width: 100%;
      text-align: center;
    }
    h3 {
      --tw-text-opacity: 1;
      color: rgb(105 105 110 / var(--tw-text-opacity));
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 1.75rem;
      margin-bottom: 0.75rem;
    }
    p {
      --tw-text-opacity: 1;
      color: rgb(105 105 110 / var(--tw-text-opacity));
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
      margin-bottom: 1rem;
    }
  }

  .wrapper {
    position: relative;
  }

  .btns {
    position: absolute;
    top: 18%;
    width: 100%;
    z-index: 99999;
    > .btn {
      cursor: pointer;
      left: 0;
      position: absolute;

      &:last-child {
        left: inherit;
        right: 0;
        z-index: 1000;
      }
    }
  }
    .swiper-pagination{
      bottom: 0;
    }

  .swiper-pagination-bullet {
    transition: 0.3s all;

    &.swiper-pagination-bullet-active {
      background: #735cc6;
      border-radius: 20px;
      width: 20px;
    }
  }
}
