@import '../../assets/styles/mixin';

.board{
    position: relative;

         &.blocked {
            .itens{
                > div{
                    div{
                        filter: grayscale(85%);
                        opacity: 0.9;
                        pointer-events: none;
                        cursor: not-allowed;
                    }
                }
            }
         }

         
    .itens{
        margin:0 auto;
        max-width: 650px;
        --box-size:163px;

        > div{
            transition: 0.3s all;
            
            button{
                overflow: hidden;
                padding: 16px 8px;
                width: 100%;

                @include bp-mobile{
                    padding:10px 5px;
                }

                .item-overlay{
                    position: absolute;
                    height: 100%;
                    left: 0;
                    top:0;
                    opacity: 0.6;
                    z-index: 100;
                    width: 100%;
                }

                &.is-next{
                    border-radius: 0 !important;
                    transition: 0.3s all;


                    svg{
                        position: relative;
                        transition:0.3s all;
                    }

                    &:hover{
                        svg{
                            left:3px;
                        }
                    }
                }

                &.is-game{
                    padding: 0;
                }

                img{
                    &:not(.coin){
                        position: absolute;
                        &.icon-game{
                            border-radius: 0 !important;
                            bottom:-40px;
                            right: -20px;
                            max-width: 120%;
                        }

                        &.image-game{
                        
                            top: 0;
                            left: 0;
                            height: 100%;
                            width: 100%;
                        }
                    }
                }

                .icon-checked{
                    height: 40px;
                    left: 50%;
                    position: absolute;
                    top:50%;
                    transform: translate(-50%,-50%);
                    width: 40px;
                    z-index: 100;

                    @include bp-mobile{
                        height: 20px;
                        width: 20px;
                    }

                    > img{
                        border-radius: 0 !important;
                        height: 40px;
                        position: relative;
                        width: 40px;

                        @include bp-mobile {
                            height: 20px;
                            width: 20px;
                        }
                    }
                }

                
            }
            &.left-bottom{
                > div{
                    border-bottom-left-radius: 70px 90px;
                    img:not(.coin),.item-overlay{
                        border-bottom-left-radius: 70px 90px;
                    }
                }
            }

            &.left-top{
                >div {
                    border-top-left-radius: 70px 90px;
                    padding-left: 0;
                    .detail{
                        left:50px;
                        justify-content: flex-end;
                    }
                    @include bp-mobile{
                        padding-left:0;
                    }
                    img,
                    .item-overlay{
                        border-top-left-radius: 70px 90px;
                    }
                }
            }

            &.right-top{
                > div{
                    border-top-right-radius:70px 90px;
                    img:not(.coin),
                        .item-overlay{
                        border-top-right-radius: 70px 90px;
                    }
                }
            }

            &.right-bottom{
                >div {
                    border-bottom-right-radius: 70px 90px !important;
                    img:not(.coin),
                        .item-overlay {
                            border-bottom-right-radius: 120px 120px !important;
                                right: 0px;
                                bottom: -15px;
                    }
                }
            }
        }  
    }
}

.pop-item {
    @include bp-mobile {
        left: 50%;
        transform: translate(-50%, 0);
    }
}