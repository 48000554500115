@import '../../assets/styles/mixin';

.navigation{
    position: relative;
    .business-name{
        color:#fff;
    }

}

 .mascote {
     right: 0;
     bottom: 0;
     width: 277px;
     transition: 0.3s all;

     

     @include bp-mobile{
        bottom: inherit;
        top:9px;
        left:50px;
        right: inherit;
        max-width: 277px;
     }

     &:hover{
        bottom:3px;
     }

    @media only screen and (min-width: 1200px) and (max-width: 1290px){
        position: absolute;
        right:-42px
    }

     p{
        @include bp-mobile{
            margin-top: 5px;
        }
     }

     &.mascote-agente-ti{
        color: #07749D;

        p{
            border:1px solid #11BEFF;
            transition: 0.3s all;
        }
     }

     &.mascote-agente-negocial{
        color:#735cc6;
        p{
            border:1px solid #465EFF;
        }
     }

     &.has-contrast{
        color:#fcfc30 !important;
        p{
            border-color:#fcfc30 !important;
        }
     }
 }