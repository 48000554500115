.main-adm-ideias {

    .Collapsible {
        display: block;
        text-decoration: none;
        position: relative;
        border-bottom: 1px solid #FCFC38;
        color: #FFF;
        margin-bottom: 5px;

        &:nth-child(2) {
            border-top: 1px solid #FCFC38;
        }

        .Collapsible__trigger {
            display: flex;
            padding: 25px;
            align-items: center;
            justify-content: flex-start;

            > span {
                color: #FCFC38;
                font-size: 1.5rem;

                b {
                    margin-right: 20px;
                    font-size: 1.4em;
                    color: #00ADEE;
                }
            }

            &.is-closed {
                &::after {
                    content: " ";
                    background-image: url("../../../assets/images/chevronDown.png");
                    position: absolute;
                    right: 15px;
                    top: 30px;
                    display: block;
                    border-radius: 100%;
                    font-size: 1.7em;
                    line-height: 0.9;
                    width: 20px;
                    height: 20px;
                    text-align: center;
                }
            }
            &.is-open {
                &::after {
                    content: " ";
                    background-image: url("../../../assets/images/chevronUp.png");
                    position: absolute;
                    right: 15px;
                    top: 30px;
                    display: block;
                    border-radius: 100%;
                    font-size: 1.7em;
                    line-height: 0.9;
                    width: 20px;
                    height: 20px;
                    text-align: center;
                }
            }
        }

        .Collapsible__contentOuter {
            padding: 0 25px;
        }

        .Collapsible__contentInner {
            > p {
                margin-bottom: 20px;
            }
        }

    }
}
.progressGeral {
    margin: 0px 0 -10px 40px;
}