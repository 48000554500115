.modalDetailBox {
    #mix {
        #avatar {
            > div {
                > div {
                    border-radius: 0;
                }
                > img {
                    border-radius: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    
    .saveImage{
        position: absolute;
        color: #fff;
        padding: 5px;
        border-radius: 5px;
        margin: 0 auto;
        left: 0;
        right: 0;
        width: 150px;
        margin-top: 15px;
    }
}