iframe {
    margin: 30px auto;
    min-height: 560px;
    width: 100%;
}

.wrapper-dialog{
    > div{
        top: 50%;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}