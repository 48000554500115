.box-store {
  background: #fff;
  width: 23%;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 2rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: relative;

  
  figure {
    width: 100%;
    img {
      width: 100%;
      height: 319px;
      // object-fit: cover;
    }
  }
  .text-store {
    p,
    span {
      color: #69696e;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 1rem;
    }
    .currency {
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        margin-right: 0.5rem;
      }
    }
    .resgatar {
      overflow-y: auto;
      padding: 1rem;
      button {
        border-radius: 10px;
        background: #735cc6;
        color: #fff;
        width: 100%;
        box-sizing: border-box;
        padding: 0.5rem;
      }
      .confirm-resgate {
        margin-bottom: 0;
        button {
          font-size: 16px;
          &:nth-child(2) {
            background: #fff;
            margin-top: 0.5rem;
            color: #735cc6;
            border: solid 1px #735cc6;
            margin-bottom: 0;
          }
        }
      }
    }
    .esgotado {
      background: #f97a70;
      text-align: center;
      padding: 0.5rem;
      margin-bottom: 1rem;
    }
  }
  &.resgatar {
    scale: 1.2;
    padding: 1rem;
    margin-bottom: -250px;
    z-index: 999;
    padding-bottom: -200px;
    height: fit-content;
    .currency {
      flex-wrap: wrap;
      .more-info {
        width: 100%;
        h2 {
          color: red;
          text-align: center;
          width: 100%;
          color: #735cc6;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin-top: 1rem;
        }
        span {
          display: flex;
          align-items: center;
          padding: 0;
          justify-content: center;
          b {
            margin-right: 0.3rem;
            font-size: 12px;
          }
          svg {
            margin-right: 0.3rem;
            height: 18px;
          }
          span {
            padding: 0;
            font-size: 12px;
          }
        }
      }
    }
  }
  &.isResgated {
    height: auto;
    margin-top: 0;
    margin-bottom: auto;
    h2 {
      color: red;
      text-align: center;
      width: 100%;
      color: #735cc6;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-top: 1rem;
    }
    button {
      border-radius: 10px;
      width: 100%;
      box-sizing: border-box;
      padding: 0.1rem;
      background: #fff;
      color: #735cc6;
      border: solid 1px #735cc6;
      font-size: 15px;
      width: 80%;
      margin: 0 auto;
      display: block;
    }
  }
  & {
   position: relative;
    .tooltip {
      display: none;
      .content-tooltip {
        background: #fff;
        padding: 1.5rem;
        position: absolute;
        bottom: 5%;
        width: 90%;
        margin-left: 5%;
        z-index: 2;
        border-radius: 10px;
       
        p {
          color: #69696e;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        .currency {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 1rem;
          svg {
            margin: 0 0.5rem;
          }
          span,
          b {
            color: #69696e;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-decoration-line: underline;
          }
          b {
            font-weight: 700;
            text-decoration: none;
          }
        }
        &::after {
          content: "";
          position: absolute;
          width: 20px;
          height: 20px;
          background: #fff;
          top: -7px;
          left: calc(50% - 10px);
          transform: rotate(45deg);
        }
      }
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgb(7 116 157 / 0.7);
        top: 0;
        left: 0;
      }
    }
    &:hover {
      .tooltip {

          display: block;
      }
    }
  }

  &.has-contrast {
    background: #000;
    .text-store{
      p,span{
        color:#fcfc30 !important;
      } 
    }

    .resgatar{
      button{
        background: #000;
        border:1px solid #fcfc30;
          color: #fcfc30;
      }
    }
  }
}
.mask-hide {
  display: none;
}
.mask-show {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: black;
  opacity: 0.5;
  z-index: 99;
}
