.section-idea {
  position: relative;
  .icon-idea{
    position: absolute;
    top: -70px;
    right: -70px;
  }
  .item-ideia {
    .thumb {
      min-width: 17%;
      flex-wrap: wrap;
      img {
        width: 50px;
        height: 50px;
        border-radius: 200px;
        object-fit: cover;
      }
    }
  }
}