.main-ideia {
  // margin-top: -85px;
  width: 100%;
  .actions {
    > .btn-open-add {
      padding: 0.7rem 4rem;
      border-radius: 7px;
      color: rgb(115, 92, 198);
      background-color: rgb(255, 255, 255);

      &.has-contrast{
        color:#fcfc30;
        background: #000;
        border:1px solid #fcfc30;
      }
    }
  }
  .trail-card {
    &.main-ideia {
      // align-items: center;
      width: 100%;
      .text-gray-300 {
        flex: 1;
      }
      .text a{
        p {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        .title {
          color: #735cc6;
        }
        .description {
          color: #69696e;
          text-decoration-line: underline;
        }
        .user {
          color: #69696e;
          font-size: 10px;
        }
      }
      .avaliation{
        display: flex;
        gap: 2rem;
        border-top: solid 1px #69696E;
        width: 100%;
        padding: 0.5rem;
        margin-top: 0.5rem;
        .like {
          button {
            display: flex;
          }
        }
      }
    }
    img {
      width: 80px;
      height: 80px;
      border-radius: 200px;
    }
  }
  .list-ideia {
    cursor: pointer;
  }
}

.verMais {
  color: #735cc6;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
}
