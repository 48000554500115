.ideia-selected {
  width: 100%;
  .avatar{
    img {
      width: 80px;
      height: 80px;
    }
  }
  .content-ideia {
    width: 100%;
    .actions-ideia {
      .curtidas {
        cursor: pointer;
        button {
          display: flex;
        }
      }
    }
    .ranking {
      display: flex;
      gap: 1rem;
      .ranking-item {
        display: flex;
        border-right: 1px solid #c6c6c6;
        padding-right: 1rem;
        p {
          strong {
            text-decoration: underline;
          }
        }
        &:last-child {
          border-right: none;
          padding-right: 0;
        }
      }
    }
    hr {
      margin-top: 2rem;
    }
  }
}
