.main-badges {
  .content-badges {
    table {
      width: 100%;
      thead {
        tr {
          th {
            background: #bdb6ff;
            padding: 0.7rem 1rem;
            color: #69696e;
            text-align: left;
            text-transform: uppercase;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            &:first-child {
              border-radius: 10px 0 0 10px;
            }
            &:last-child {
              border-radius: 0 10px 10px 0;
              // text-align: center;
            }
          }
        }
      }
      tbody {
        tr {
          border-bottom: solid 1px #fff;
          td {
            padding: 0.7rem;
            color: var(--branco, #fff);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            span {
              color: #fcfc30;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 20px;
            }
            img {
              width: 100px;
              margin: 1rem 0;
            }
            &.incomplet {
              img {
                opacity: 0.5;
              }
            }
            .necessarios {
              display: flex;
              gap: 2rem;
              justify-content: center;
              align-items: center;
              > div {
                display: flex;
                align-items: center;
                gap: 0.5rem;
              }
            }
          }
        }
      }
    }
  }
}
